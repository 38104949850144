import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'

const Contact = () => (
	<Layout>
		<SEO title="Contact Us" description="Contact Page" />
		<p>Contact Page</p>
	</Layout>
)

export default Contact
